import React from 'react';
import styled from 'styled-components';

import MainContent from '@/components/main-content';
import colors from '@/assets/colors';
import { media } from '@/utils';
import '@/stylesheets/about';
import decor from '@/assets/decor-medium.svg';
import silvestre from '@/assets/silvestre.svg';

const Container = styled(MainContent)`
  display: flex;
  justify-content: flex-end;
  padding: 4em 0;

  ${media.desktop`
    align-items: flex-start;
  `};

  ${media.tablet`
    flex-direction: column;
    padding: 0;
  `};

  ${media.phone`
    flex-direction: column;
    padding: 0;
  `};

  &::before {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    content: '';
    height: 100vh;
    left: 0;
    mask-image: linear-gradient(to bottom, transparent, white);
    opacity: .05;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -1;
  }
`;

const Illustration = styled.img`
  bottom: 0;
  height: 110vh;
  mix-blend-mode: overlay;
  position: absolute;
  transform: translate3d(0, 0, 0);
  opacity: .5;
`;

const Title = styled.h1`
  color: ${colors.brand.accent};
  margin: 0 1em 0 0;
  white-space: nowrap;
`;

const Info = styled.div`
  padding: 3em 4em;
  overflow: hidden;
  position: relative;
  width: 45%;

  &::after {
    background: url('${decor}');
    background-size: contain;
    content: '';
    height: 2rem;
    left: 1.5rem;
    position: absolute;
    top: 5rem;
    width: 2rem;
  }

  &::before {
    background: rgba(0, 0, 0, .35);
    content: '';
    height: 8em;
    left: calc(50% - 10em);
    position: absolute;
    top: calc(50% - 4em);
    transform: rotate(-15deg) scale(3);
    width: 20em;
    /* z-index: -1; */

    /* @supports (backdrop-filter: blur(1em)) {
      background: rgba(0, 0, 0, .25);
      backdrop-filter: blur(1em);
    } */
  }

  ${media.tablet`
    width: auto;
  `};

  ${media.phone`
    margin: 0 -1em;
    padding: 1em;
    width: auto;
  `};

  p {
    text-shadow: 0 .0625em 2em rgba(0, 0, 0, .125);
    position: relative;
    z-index: 1;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const About = () => {
  return (
    <Container>
      <Title>About me</Title>
      <Info>
        <p>I'm a self taught fullstack JavaScript developer, graphics designer, and often times musician, with just about a decade of experience. I'm
          open minded and, although I work remotely, I do love exchanging ideas with other developers from around the world.</p>
        <p>I thrive to keep current with the latest development trends while not overlooking battle-tested technologies. I'm also
          an Open Source enthusiast. I participate in OSS projects and I start my own rather frequently.</p>
        <p>I love to build service-oriented, real-time applications using Node, FeathersJS, MongoDB/PostgreSQL and React/React Native.</p>
      </Info>
      <Illustration alt="illustration" src={silvestre} />
    </Container>
  );
};

export default About;
